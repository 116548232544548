import { WixCodeApiFactoryArgs } from '@wix/thunderbolt-symbols'
import { namespace } from '../symbols'
import type {
	NativeMobileWixCodeSdkHandlers,
	NativeMobileWixCodeSdkWixCodeApi,
	NativeMobileWixCodeSdkSiteConfig,
} from '../types'

/**
 * This is SDK Factory.
 * Expose your Velo API
 */
export function NativeMobileSdkFactory({
	featureConfig,
	handlers,
}: WixCodeApiFactoryArgs<NativeMobileWixCodeSdkSiteConfig, unknown, NativeMobileWixCodeSdkHandlers>): {
	[namespace]: NativeMobileWixCodeSdkWixCodeApi
} {
	return {
		[namespace]: {
			someKey: featureConfig.someKey,
			doSomething: handlers.doSomething,
		},
	}
}
